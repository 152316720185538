<template>
  <div
    v-if="calculator"
    class="border-b-darkBlue flex items-center justify-between gap-10 border-b border-b-[2px] py-[9px]"
  >
    <span class="font-bionM font-semibold uppercase max-sm:text-base">
      {{ enterTitle }}
    </span>
    <quantity></quantity>
  </div>

  <div class="mb-25" :class="{'flex flex-col-reverse gap-5': !calculator}">
    <!-- <product-select-plan @selectPlan="emitSelectedPlan"></product-select-plan> -->
    <div class="relative cursor-pointer font-bionM">
      <!-- dropdown -->
      <div
        ref="dropdownRef"
        class="flex select-none items-center justify-between border-[2px] border-b-0 border-transparent py-[9px]"
        :class="{
          '!border-ss3_gray  bg-pastelPink pl-15 pr-5':
            isOpenDropDown && typePurchase === 'subscribe',
          'border-t-0': calculator,
          '!pl-0': !calculator,
        }"
        @click.prevent="typePurchase === 'subscribe' && onOpenDropdown()"
      >
        <span v-if="calculator" class="relative font-semibold uppercase">
          FREQUENCY
        </span>

        <span class="flex items-center justify-between capitalize">
          <span
            v-if="!calculator && typePurchase === 'subscribe'"
            class="ml-15 mr-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M12.5556 9.08769C11.7584 11.3636 9.54315 12.9999 6.9358 12.9999C3.65736 12.9999 1 10.4135 1 7.2226C1 4.03171 3.65636 1.44434 6.9348 1.44434C8.57402 1.44434 10.0582 2.09069 11.1324 3.13615"
                stroke="#3F3F3F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M13.9999 0V5.77778H8.22217L13.9999 0Z" fill="#3F3F3F" />
            </svg>
          </span>
          <div class="mr-[18px] max-sm:text-sm">
            <span v-if="calculator">
              {{ calcBars }}
              {{ computedFrequency ? computedFrequency : "Jars" }}</span
            >

            <span v-if="typePurchase === 'subscribe'">
              <template v-if="calculator"
                >,
                {{
                  dropDownSelected?.name ||
                  sellingPlan[0]?.name
                    ?.replace(/^Delivery\s+/, "")
                    ?.replace(/\s+with.*$/, "")
                }}
              </template>
              <template v-else> Deliver {{ dropDownSelected?.name }} </template>
            </span>
          </div>

          <svg
            v-if="typePurchase === 'subscribe'"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            viewBox="0 0 24 15"
            fill="none"
            class="max-sm:w-[20px]"
            :class="{'rotate-180': isOpenDropDown}"
          >
            <path
              d="M2 2L12 12L20 2"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </span>
      </div>
      <ul
        v-if="isOpenDropDown && typePurchase === 'subscribe'"
        class="max-h-[450px] overflow-y-auto border-[2px] border-t-0 bg-pastelPink px-15 pb-15 max-sm:mb-20"
      >
        <li
          class="hover:text-darkBlue mb-5 flex items-center justify-between last:mb-0 max-sm:text-sm"
          :class="{'!font-semibold': dropDownSelected.planId === plan.planId}"
          v-for="plan in sellingPlan"
          :key="plan.id"
          @click.stop="onSelectDropDown(plan)"
        >
          {{ plan?.name?.replace("subscription", "") }}
          <span
            v-if="recommendedPlan === plan.planId && calculator"
            class="flex h-[20px] items-center justify-center bg-ss3_purple px-5 text-xs text-white"
            >RECOMMENDED</span
          >
        </li>
      </ul>
    </div>

    <div class="ml-auto mt-15 w-full">
      <ul class="text-right font-bionM">
        <li
          @click="onSelectPurchase('oneTime')"
          class="gap-1 mb-15 flex cursor-pointer whitespace-nowrap max-sm:mb-10 sm:items-center"
        >
          <div
            class="flex flex-auto gap-[9px] max-sm:whitespace-normal max-sm:text-left sm:items-center"
          >
            <div
              :key="selectedIndex"
              class="flex h-[20px] w-[20px] flex-[0_0_20px] items-center justify-center rounded-full border-[2px] bg-pastelPink max-sm:mt-5"
              :class="[typePurchase === 'oneTime' && 'border-ss3_purple']"
            >
              <span
                class="h-full w-full rounded-full bg-pastelPinkL transition-all duration-500"
                :class="[
                  typePurchase === 'oneTime' ? 'opacity-100' : 'opacity-0',
                ]"
              ></span>
            </div>
            One Time Purchase
            <span :class="calculator ? '' : 'ml-auto'">{{
              oneTimePurchasePrice
            }}</span>
          </div>
          <template v-if="calculator"> ${{ calculatedPrice }}/Day </template>
        </li>
        <li
          @click="onSelectPurchase('subscribe')"
          class="gap-1 flex cursor-pointer whitespace-nowrap sm:items-center"
          :key="priceOption"
        >
          <div
            class="flex flex-auto gap-[9px] max-sm:whitespace-normal max-sm:text-left sm:items-center"
          >
            <div
              class="flex h-[20px] w-[20px] flex-[0_0_20px] items-center justify-center rounded-full border-[2px] bg-white"
              :class="[typePurchase === 'subscribe' && 'border-ss3_purple']"
            >
              <span
                class="h-full w-full rounded-full bg-pastelPinkL transition-all duration-500"
                :class="[
                  typePurchase === 'subscribe' ? 'opacity-100' : 'opacity-0',
                ]"
              ></span>
            </div>
            <tempalte v-if="calculator"
              >Subscribe & Save ${{ subscribePrice }}</tempalte
            >
            <template v-else
              >Subscribe & Save 10%
              <span class="ml-auto">${{ subscribePrice }}</span></template
            >
          </div>
          <template v-if="calculator">
            ${{ calculatedPriceOption }}/Day
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Results",
  props: {
    price: {
      type: Number,
      default: 0,
    },
    bars: {
      type: Number,
      default: 42,
    },
    pounds: {
      type: Number,
      default: 0,
    },
    data: {
      type: String,
      default: [],
    },
    frequency: {
      type: String,
    },
    calculator: {
      type: Boolean,
    },
    enterweighttitle: {
      type: String,
    },
  },
  data: () => ({
    priceOption: 0,
    selingPlan: true,

    isOpenDropDown: false,
    dropDownSelected: {
      name: "",
      planId: null,
    },
    typePurchase: "subscribe",
    recommendedPlan: null,
    selectedPlan: 0,
  }),
  computed: {
    ...mapState("dog-weight", ["quantity", "selingPlan"]),
    ...mapState("product", {
      variantIndex: "selectedIndex",
      productQuantity: "quantity",
    }),
    poundsComputed() {
      return (
        this.data?.variants[this.variantIndex]?.poundsMetafield || this.pounds
      );
    },
    barsComputed() {
      return this.data?.variants[this.variantIndex]?.barsMetafield || this.bars;
    },
    calculatedPrice() {
      // console.log(this.barsComputed)
      const basePrice = this.oneTimePurchasePrice?.slice(1) || 0;
      const pricePerBar = Number(basePrice) / this.barsComputed;

      const adjustedPricePerDay =
        pricePerBar *
        (this.quantity / this.poundsComputed) *
        this.productQuantity;

      return isFinite(adjustedPricePerDay)
        ? adjustedPricePerDay.toFixed(2)
        : "0.00";
    },

    calculatedPriceOption() {
      // console.log(this.poundsComputed)
      const basePrice = Number(this.subscribePrice) / this.barsComputed;

      const adjustedPriceOption =
        basePrice *
        (this.quantity / Number(this.poundsComputed)) *
        this.productQuantity;

      return isFinite(adjustedPriceOption)
        ? adjustedPriceOption.toFixed(2)
        : "0.00";
    },
    sellingPlan() {
      return this.data?.variants ? this.data?.variants[0]?.sellingPlans : [];
    },
    calcBars() {
      return this.barsComputed * this.productQuantity;
    },
    findIndexPlan() {
      return this.data?.variants[this.variantIndex]?.sellingPlans.findIndex(
        plan => Number(plan.planId) === Number(this.recommendedPlan),
      );
    },
    oneTimePurchasePrice() {
      return this.data?.variants
        ? this.data?.variants[this.variantIndex]?.displayPrice
        : "0.00";
    },
    subscribePrice() {
      return this.data?.variants
        ? this.data?.variants[this.variantIndex]?.sellingPlans[
            this.findIndexPlan
          ]?.price / 100
        : "0.00";
    },
    enterTitle() {
      return this.enterweighttitle
        ? `Enter ${this.enterweighttitle} Weight`
        : `Enter ${this.data?.variants[this.variantIndex]?.enterWeightMetafield || "pet"} Weight`;
    },

    computedFrequency() {
      return (
        this.data?.variants[this.variantIndex]?.typeFrequencyMetafield ||
        this.frequency
      );
    },
  },
  watch: {
    quantity() {
      this.estimatedSupply();
    },
    productQuantity() {
      this.estimatedSupply();
    },
  },

  methods: {
    ...mapActions("product", ["setSellingPlan"]),

    estimatedSupply() {
      // this.quantity = dog weight

      const baseFrequency = Math.floor(
        this.bars / (this.quantity / this.pounds) / 7,
      ); // [42 bar count / (40lbs dog / 20 Pounds per bar daily)] / 7 days

      const adjustedFrequency = baseFrequency * this.productQuantity;

      // console.log('bars',this.bars, 'pounds', this.pounds , 'q', this.quantity );

      this.checkElementAvailability(adjustedFrequency);
    },
    checkElementAvailability(i) {
      if (this.sellingPlan !== null) {
        // Extract all options
        const plans = this.sellingPlan
          .map(option => {
            const text = option.name?.trim();
            const monthsMatch = text?.match(/(\d+) month/);
            return {
              months: monthsMatch ? parseInt(monthsMatch[1], 10) : null,
              option: option,
            };
          })
          .filter(plan => plan.months !== null)
          .sort((a, b) => a.months - b.months);
        let closestPlan = plans.reduce((prev, curr) => {
          return Math.abs(curr.months - i) < Math.abs(prev.months - i) &&
            curr.months <= i
            ? curr
            : prev;
        }, plans[0]);
        this.dropDownSelected = {
          name: closestPlan?.option?.name
            ?.replace(/^Delivery\s+/, "")
            ?.replace(/\s+with.*$/, ""),
          planId: closestPlan?.option?.planId,
        };
        this.recommendedPlan = closestPlan?.option?.planId;
      }
    },

    onOpenDropdown() {
      this.isOpenDropDown = !this.isOpenDropDown;
    },

    onSelectDropDown(plan) {
      // console.log(plan);
      const planObj = {
        name: plan?.name
          ?.replace(/^Delivery\s+/, "")
          ?.replace(/\s+with.*$/, ""),
        planId: plan.planId,
      };

      this.dropDownSelected = planObj;
      this.setSellingPlan(plan.planId);

      this.isOpenDropDown = false;
    },
    onSelectPurchase(type) {
      this.typePurchase = type;
      // console.log(type)
      if (type === "subscribe") {
        // console.log(this.dropDownSelected)
        this.setSellingPlan(this.dropDownSelected.planId);
      } else {
        this.setSellingPlan(null);
      }
    },
    emitSelectedPlan(planValue) {
      this.selectedPlan = planValue;
      this.estimatedSupply();
    },
  },
  mounted() {
    this.onSelectDropDown({
      name: this.data?.variants[0]?.sellingPlans[2]?.name,
      planId: this.data?.variants[0]?.sellingPlans[2]?.planId,
    });
    this.estimatedSupply();
    // console.log(this.data, this.data?.variants, 'plan', this.recommendedPlan);
    // console.log(this.data?.variants[this.variantIndex]);
    // console.log(this.data)
  },
};
</script>
